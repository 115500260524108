<template>
  <div>
    <CampusAttendanceFilters
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <section class="main-content rounded-md mt-2">
      <CalendarActionsWrapper
        :calendar-date="filterView === 'Week' ? dateTimeRange : currentDate"
        :filter-view="filterView"
        :filters="['Day']"
        @selectDate="setCalendarDate"
        @selectDateRange="setCalendarDateRange"
        @setFilter="setFilter"
        @applyFilter="applyDateFilter"
      ></CalendarActionsWrapper>
      <template v-if="isLoading">
        <Loader class="mt-20 mb-40 pt-12 pb-40" :content="true" />
      </template>
      <div v-else class="mt-2">
        <UiCalendar
          v-if="filterView === 'Month'"
          :key="forceRenderCalendar"
          :calendar-date="currentDate"
          class="bg-primary-white"
        >
          <!-- change the way of passing Dynamic Slot Names -->
          <template
            v-for="(event, index) in attendanceRecords"
            v-slot:[`day-${getDay(event.date)}`]
          >
            <div v-if="event.hasOwnProperty.call(event, 'isAction')" :key="index">
              <div
                v-if="!event.isAction"
                class="time-schedule flex justify-center items-center gap-1 text-xs status mb-1 cursor-pointer"
                @click="singleRecord(event)"
              >
                <p
                  class="dot dot_size h-2 w-2 rounded-full"
                  :style="{ backgroundColor: event.checkedStatus.color }"
                ></p>
                <span
                  class="text-white text-xs inline-block rounded-sm whitespace-no-wrap text_size presentDate"
                  :style="{
                    backgroundColor: event.checkedStatus.presentColor,
                  }"
                >
                  {{ `${event.checked_time}` }}
                </span>
              </div>
              <div v-if="event.isAction" class="time-schedule viewMore">
                <p
                  class="font-roboto font-medium text-primary-grey-light viewMoreText cursor-pointer"
                  @click="clickDay(event.date)"
                >
                  {{ $t(`schedule.View More`) + ' ...' }}
                </p>
              </div>
            </div>
          </template>
        </UiCalendar>
        <WeeklyView
          v-else-if="filterView === 'Week'"
          :key="`Calender-${forceRenderCalendar}`"
          :theads="dayRange"
          :table-time="[timeRange[0] || INITIAL_START_TIME, timeRange[1] || INITIAL_END_TIME]"
          :events="events"
          :hide-overlap="true"
          :hide-actions="true"
          class="border rounded-lg border-primary-grey bg-primary-white"
          @viewEvent="viewEvent"
        ></WeeklyView>
        <AttendanceDailyView
          v-else-if="filterView === 'Day'"
          :attendance-records="attendanceRecords"
          :current-date="currentDate"
          :filters-data="filtersData"
          :table-headers="tableHeaders"
          user-name="Student Name"
          :is-loading="isLoading"
          class="-mt-1"
        >
          <template v-slot:notFound>
            <NoRecordFound />
          </template>
        </AttendanceDailyView>
      </div>
      <Pagination
        v-if="totalAttendanceCount > 10 && filterView === 'Day'"
        v-show="!isLoading"
        :key="`Pagination${forceRender}`"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(totalAttendanceCount, filteredRecordLimit)"
        @filterRecord="applyDateFilter"
      />
    </section>
    <UiModalContainer
      footer-classes="flex justify-end"
      :modal-show="dayModal"
      :modal-width="40"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        {{ $t('attendanceTranslation.Check In/Out Details') }}
      </template>
      <template v-slot>
        <div v-for="event in detailsEventsArr" :key="event.id" class="px-5 mb-8">
          <div class="flex flex-col gap-5 sm:flex-row mt-6">
            <span class="text-2xl text-label-text font-bold">
              {{
                event.checked_type === 'checked_in'
                  ? $t('rightBar.RB_CHECKED1')
                  : $t('rightBar.RB_CHECKED2')
              }}
              : {{ event.comment }}
            </span>
            <span class="subjectColor"></span>
          </div>
          <div class="flex justify-between">
            <div class="py-3 flex flex-1 items-center">
              <icon icon="calender" color="primary-green" height="21" width="18" />
              <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                {{ event.date || event.day }}
              </span>
            </div>
            <div class="py-3 flex flex-1 clock-icon items-center">
              <icon icon="clock" color="primary-green" height="21" width="18" />
              <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                {{ event.checked_time }}
              </span>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">Marked By</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ event.first_name }} {{ event.last_name }}
                </span>
              </div>
            </div>
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">Total Count</span>
                :

                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ event.checked_count }}
                </span>
              </div>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">First Name</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ event.first_name }}
                </span>
              </div>
            </div>
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">Last Name</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ event.last_name }}
                </span>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </template>
    </UiModalContainer>
    <StudentCheckStatusModal
      v-if="studentCheckInModal || studentCheckOutModal"
      :type="studentCheckInModal ? 'checkIn' : 'checkOut'"
      :show="studentCheckInModal || studentCheckOutModal"
      @close="closeStudentCheckModal"
      @refetch="refetch"
    />
    <WeeklyModal
      footer-classes="flex justify-end"
      :modal-show="routinesDetailModal"
      :modal-width="40"
      @handleClick="handleRoutinesDetailClick"
    >
      <template v-slot:header>
        {{ $t('attendanceTranslation.Student Attendance Details') }}
      </template>
      <template v-slot>
        <div v-for="sch in detailsEventsArr" :key="sch.id" class="px-3 md:px-5 mb-8">
          <div class="flex flex-col gap-5 sm:flex-row mt-6">
            <span class="text-2xl text-label-text font-bold">
              {{
                sch.title === 'checked_in'
                  ? $t('rightBar.RB_SCH_CHECKED1')
                  : $t('rightBar.RB_SCH_CHECKED2')
              }}
            </span>
            <span class="subjectColor"></span>
          </div>
          <div class="flex flex-1 justify-between">
            <div class="py-3 flex flex-1 items-center">
              <icon icon="calender" color="primary-green" height="21" width="18" />
              <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                {{ sch.date || sch.day }}
              </span>
            </div>
            <div class="py-3 flex flex-1 clock-icon items-center">
              <icon icon="clock" color="primary-green" height="21" width="18" />
              <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                {{ getTimeOnly(sch.startTime) }}
              </span>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">Marked By</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.checked_by_fname }} {{ sch.checked_by_lname }}
                </span>
              </div>
            </div>
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">Total Count</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.check_count }}
                </span>
              </div>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">First Name</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.checked_by_fname }}
                </span>
              </div>
            </div>
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">Last Name</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.checked_by_lname }}
                </span>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </template>
    </WeeklyModal>
  </div>
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import icon from '@components/icons/icon.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import CalendarActionsWrapper from '@components/Calendar/CalendarActionsWrapper.vue'
import UiCalendar from '@components/Calendar/index.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { formatDateString } from '@utils/format-date'
import timeMixin from '@src/mixins/timeMixin'
import WeeklyView from '../CheckInOutWeekly.vue'
import CampusAttendanceFilters from '@views/attendance/campus-attendance/filters/CampusAttendanceFilters.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

import Pagination from '@components/BaseComponent/Pagination.vue'
import generalMixins from '@src/mixins/general-mixins.js'
import { getStartTimeOfTheDay, getEndTimeOfTheDay } from '@utils/moment.util'
/* UTILS */
import { getTimeRange } from '@utils/timeTableUtils'
import { isEmpty } from 'lodash'
import {
  getStudentAttendanceWeek,
  getSubjectEvent,
} from '@src/router/views/attendance/CheckInOutUtils.js'
import { convertUTCTimeIntoLocalTime, objectDeepCopy } from '@/src/utils/generalUtil'
import fileMixin from '@src/mixins/file-mixins'
import { buildWhereQuery } from '@/src/utils/filters'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import {
  INITIAL_START_TIME,
  INITIAL_END_TIME,
  rightBarStats,
  rightBarStatus,
} from '@/src/constants/attandance/attandance-constant'
import campusAttendanceData from '@/src/router/views/attendance/campus-attendance/campusStudentAttendance.json'
import { TENANT_ROLES } from '@/src/constants/user-roles-constants.js'
import StudentCheckStatusModal from './MarkCampusAttendanceModal.vue'
import AttendanceDailyView from '@/src/router/views/attendance/campus-attendance/CampusAttendanceDailyView.vue'
import WeeklyModal from '@components/UiElements/UiModalContainer.vue'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
export default {
  name: 'CampusStudentAttendance',
  components: {
    CalendarActionsWrapper,
    UiCalendar,
    CampusAttendanceFilters,
    UiModalContainer,
    icon,
    Loader,
    WeeklyView,
    Pagination,
    NoRecordFound,
    StudentCheckStatusModal,
    AttendanceDailyView,
    WeeklyModal,
  },
  mixins: [timeMixin, fileMixin, generalMixins],
  data() {
    return {
      filteredRecordLimit: 10,
      isEmpty,
      filtersData: {},
      defaultSelectedColumns: campusAttendanceData.defaultSelectedColumns,
      tableHeaders: campusAttendanceData.tableHeaders,
      isLoading: false,
      forceRender: 1,
      forceRenderCalendar: 1,
      filterView: 'Day',
      title: 'title',
      attendanceTranslation: 'attendanceTranslation',
      currentDate: new Date(),
      dateTimeRange: null,
      isMobileDevices: window.matchMedia('(max-width: 1023px)').matches,
      dayRange: ['monday', 'friday'],
      timeRange: ['0:00 am', '0:00 am'],
      tempDate: {},
      routinesDetailModal: false,
      attendanceRecords: [],
      respDataObjDump: [],
      events: [],
      studentlist: [],
      dayModal: false,
      detailsEventsArr: [],
      INITIAL_START_TIME,
      INITIAL_END_TIME,
      GENERAL_CONSTANTS,
      totalAttendanceCount: 0,
    }
  },
  page: campusAttendanceData.page,
  computed: {
    getTimeOnly() {
      return (time) => {
        const timeObj = time.split(' ')
        return timeObj[0]
      }
    },
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope?.id,
      currentCampusScope: (state) => state.layout.currentCampusScope?.id,
      currentClassScope: (state) => state.layout.currentClassScope?.id,
      studentListByClass: (state) => state.student.currentStudentList,
      studentCheckInModal: (state) => state.layout.studentCheckInModal,
      studentCheckOutModal: (state) => state.layout.studentCheckOutModal,
      BulkCheckSubmit: (state) => state.layout.BulkCheckSubmit,
      BulkCheckInModal: (state) => state.layout.BulkCheckInModal,
      BulkCheckOutModal: (state) => state.layout.BulkCheckOutModal,
    }),
    ...mapGetters('layout', ['campusTimeZone']),
  },
  watch: {
    BulkCheckInModal: {
      deep: true,
      immediate: true,
      handler() {
        if (this.BulkCheckInModal) {
          this.$router?.push({
            name: 'studentBulkCheckIn',
          })
          this.setBulkCheckInModal()
          this.mobileRightBar()
        }
      },
    },
    BulkCheckOutModal: {
      deep: true,
      immediate: true,
      handler() {
        if (this.BulkCheckOutModal) {
          this.$router?.push({
            name: 'mark-student-section-attendance',
          })
          this.setBulkCheckOutModal()
          this.mobileRightBar()
        }
      },
    },
    currentSectionScope: {
      deep: true,
      handler() {
        this.applyDateFilter()
      },
    },
    currentClassScope: {
      deep: true,
      handler() {
        this.applyDateFilter()
      },
    },
    currentCampusScope: {
      deep: true,
      handler(value) {
        if (value) this.applyDateFilter()
      },
    },
  },
  /*
   * Set current date with new Date() method
   * Call setRightBarStast function to set rightbardata
   */
  created() {
    this.mobileRightBar()
    this.currentDate = new Date()
    const stat = {}
    this.setRightBarStats(stat)
  },
  mounted() {
    this.applyDateFilter()
  },
  methods: {
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.applyDateFilter()
    },
    refetch() {
      this.applyDateFilter()
      this.forceRender++
    },
    applyDateFilter(range) {
      let paginationPayload = paginationRangeHandler(range)
      let data = {
        ...paginationPayload,
      }
      if (this.student_id) {
        data.user_id = this.student_id
      }
      data = this.filtersData ? { ...data, ...{ $where: this.filtersData?.$where } } : data
      switch (this.filterView) {
        case 'Month':
          data.month_year = formatDateString(this.currentDate)
          this.loadMonthsRecord(data)
          break
        case 'Week':
          data.from_date = formatDateString(this.dateTimeRange.startDate)
          data.to_date = formatDateString(this.dateTimeRange.endDate)

          this.loadWeekRecord(data)
          break
        case 'Day':
          data.date = formatDateString(this.currentDate)
          if (this.currentCampusScope) this.loadDayRecord(data)
      }
    },
    /**
     * Mobile Right Bar
     * @description Closes right bar in mobile view when an action is performed
     */
    mobileRightBar() {
      if (this.isMobileDevices) this.$store.commit('layout/SET_TAB_RIGHT_BAR', false)
    },
    /*
     * Set right bar static data and update it after server response
     * Get stat as param which contain stats get from server
     */
    setRightBarStats(stat, status) {
      const rightBarContent = {
        header: {
          title: 'Attendance',
          buttons: [
            {
              title: 'RB_MARK_ATTENDANCE_IN',
              classes: ['primary-button-right'],
              action: {
                name: 'layout/setStudentCheckInModal',
                payload: {},
              },
              permissions: {
                roles: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.CAMPUS_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                ],
              },
            },
            {
              title: 'RB_MARK_ATTENDANCE_OUT',
              classes: ['primary-button-right'],
              action: {
                name: 'layout/setStudentCheckOutModal',
                payload: {},
              },
              permissions: {
                roles: ['super_admin', 'campus_admin', 'section_teacher'],
              },
            },
          ],
        },
        stats: rightBarStats(
          stat.total_students,
          stat.total_records,
          stat.total_checked_in,
          stat.total_checked_out,
          stat.unique_checked_in,
          stat.unique_checked_out,
        ),
      }
      this.setRightbarContent(rightBarContent)
    },
    setCalendarDate(dt) {
      this.currentDate = dt
    },
    clickDay(date) {
      this.detailsEventsArr = this.attendanceRecords.filter((single) => single.date === date)
      this.dayModal = true
    },
    /*
     * Open single record model to show record details
     */
    singleRecord(record) {
      this.detailsEventsArr = [record]
      this.dayModal = true
    },
    handleClick(status) {
      switch (status) {
        case 'close':
          this.dayModal = false
          break
        case 'cancel':
          this.dayModal = false
          break
      }
    },
    setCalendarDateRange(dtr) {
      this.tempDate.dateTimeRange = this.dateTimeRange
      this.dateTimeRange = dtr
      // function use For set Range for Weekly call
      this.initializeFilterForWeek(this.dateTimeRange.startDate, this.dateTimeRange.endDate)
      this.forceRenderCalendar++
    },
    /*
     * Load check-in-out weekly  data
     */
    initializeFilterForWeek(startingDate, endingDate = null) {
      const startDate = new Date(startingDate)
      const endDate = new Date(startingDate)
      // Setting this to only get range for 7 days apart
      endDate.setDate(endDate.getDate() + 6)
      const startDayOfWeek = formatDateString(startDate, 'EEEE').toLowerCase()
      const endDayOfWeek = formatDateString(endDate, 'EEEE').toLowerCase()
      this.dateTimeRange = {
        startDate: startDate,
        endDate: endDate,
      }
      this.tempDate.dayRange = this.dayRange
      this.dayRange.splice(0, 1, startDayOfWeek)
      this.dayRange.splice(1, 1, endDayOfWeek)
    },
    setFilter(filterView) {
      this.attendanceRecords = []
      this.filterView = filterView
      if (filterView === 'Week') {
        this.initializeFilterForWeek(this.currentDate)
      }
      this.applyDateFilter()
      this.forceRenderCalendar++
    },
    getDay(date) {
      return new Date(date).getDate()
    },
    /*
     * Get resp as Param contain schedule object
     * Transform it into a format use for monthly view
     */
    transformRecordsForMonth(resp) {
      const records = []
      let newRecord = {}
      Object.keys(resp).forEach((date) => {
        let key = 0
        for (let i = 0; i < resp[date].length; i++) {
          const record = resp[date][i]
          if (i === 0) {
            newRecord = {
              id: record.bulk_check_id,
              date: record.checked_at.split('T')[0],
              checked_type: record.checked_type,
              checked_count: record.check_count,
              last_name: record.checked_by_lname,
              first_name: record.checked_by_fname,
              checked_time: convertUTCTimeIntoLocalTime(record.checked_at.split('T')[1], 'hh:mm A'),
              name: record.checked_by_fname,
              comment: record?.comment,
              checkedStatus: {
                color: record.checked_type === 'checked_in' ? '#267C26' : '#921A1D',
                presentColor: '#267C26',
              },
              isAction: false,
              time: record.checked_at,
            }
            records.push(newRecord)
          } else if (i > 0) {
            if (resp[date][i].bulk_check_id !== resp[date][i - 1].bulk_check_id) {
              key++
              newRecord = {
                id: record.bulk_check_id,
                date: record.checked_at.split('T')[0],
                checked_type: record.checked_type,
                checked_count: record.check_count,
                last_name: record.checked_by_lname,
                first_name: record.checked_by_fname,
                comment: record?.comment,
                checked_time: convertUTCTimeIntoLocalTime(
                  record.checked_at.split('T')[1],
                  'hh:mm A',
                ),
                checkedStatus: {
                  color: record.checked_type === 'checked_in' ? '#267C26' : '#921A1D',
                  presentColor: '#267C26',
                },
                time: record.checked_at,
              }
              if (key < 3) {
                newRecord.isAction = false
              } else if (key === 3) {
                newRecord.isAction = true
              }
              records.push(newRecord)
            }
          }
        }
      })
      this.attendanceRecords = records
    },
    loadMonthsRecord(data = {}) {
      this.isLoading = true
      this.loadCampusStudentsAttendance(data)
        .then((resp) => {
          this.transformRecordsForMonth(resp.records)
          const status = rightBarStatus()
          this.setRightBarStats(resp.meta, status)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    loadDayRecord(data = {}) {
      this.isLoading = true
      let range = { skip: data.skip, limit: data.limit }
      let date = data.date
      let payload = {
        ...range,
        ...{
          $where: {
            ...data?.$where,
            checked_at: {
              [FILTER_KEYS.GREATER_THAN_EQUAL]: `${getStartTimeOfTheDay(date)}${
                this.campusTimeZone
              }`,
              [FILTER_KEYS.LESS_THAN_EQUAL]: `${getEndTimeOfTheDay(date)}${this.campusTimeZone}`,
            },
          },
        },
      }
      this.loadCampusStudentsAttendance(payload)
        .then((resp) => {
          this.totalAttendanceCount = resp.meta.total_records
          this.attendanceRecords = resp.records
          this.attendanceRecords.forEach((attendance) => {
            fullName(attendance.user)
          })
          this.setRightBarStats(resp.meta)
        })
        .catch(() => {
          this.attendanceRecords = []
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    loadWeekRecord(data = {}) {
      let attendances = null
      let timeRange = null
      this.isLoading = true
      this.loadCampusStudentsAttendance(data)
        .then((resp) => {
          const status = rightBarStatus()
          this.setRightBarStats(resp.meta, status)
          this.respDataObjDump = resp.records
          // format the Data before sending to a weeklyView component
          attendances = getStudentAttendanceWeek(resp.records)
          // Here we send the For data to A function to set
          timeRange = getTimeRange(attendances)
          this.timeRange.splice(0, 1, timeRange[0])
          this.timeRange.splice(1, 1, timeRange[1])
          this.events = attendances
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleRoutinesDetailClick(eventType) {
      if (eventType === 'close') {
        this.routinesDetailModal = false
      }
    },
    viewEvent(eventId) {
      let detailsEventsArr = []
      const event = this.events.find((event) => event.id === eventId)
      if (event) {
        detailsEventsArr.push(event)
        if (event?.isOverlapped) {
          const { overlappedIds } = event || {}
          let allOverlappedEvents = null
          const attendances = []
          // format Data
          Object.keys(this.respDataObjDump).forEach((date) => {
            this.respDataObjDump[date].forEach((record) => {
              let date = record.date + 'T' + record.checked_at
              date = new Date(date)
              let endTime = date.setMinutes(date.getMinutes() + 30)
              endTime = new Date(endTime)
              const endTimeFormat =
                endTime.getHours().toString().padStart(2, '0') +
                ':' +
                endTime.getMinutes().toString().padStart(2, '0') +
                ':' +
                endTime.getSeconds().toString().padStart(2, '0')
              const event = getSubjectEvent(record, endTimeFormat)
              attendances.push(event)
            })
          })
          // Find overlap events array and pass to modal
          allOverlappedEvents = overlappedIds.map((id) => {
            return attendances.find((obj) => obj.id === id)
          })
          detailsEventsArr = [...detailsEventsArr, ...allOverlappedEvents]
        }
        this.detailsEventsArr = detailsEventsArr
        this.routinesDetailModal = true
      }
    },
    closeStudentCheckModal() {
      if (this.studentCheckInModal) {
        this.$store.dispatch('layout/setStudentCheckInModal')
      } else if (this.studentCheckOutModal) {
        this.$store.dispatch('layout/setStudentCheckOutModal')
      }
    },
    ...mapActions('student', ['loadCampusStudentsAttendance']),
    ...mapActions('layout', ['setRightbarContent', 'setBulkCheckInModal', 'setBulkCheckOutModal']),
  },
}
</script>

<style lang="scss">
.widthItems {
  min-width: 31%;
}
.present {
  color: #267c26;
  background-color: #e9f2e9;
}
.delay {
  color: #ffc201;
  background-color: #fff9e6;
}
.absent {
  color: #ea3535;
  background-color: #fce1e1;
}
.halfday {
  color: #ff9421;
  background-color: #fff6ed;
}
.holiday {
  color: #357adb;
  background-color: #eaf1ff;
}
</style>
