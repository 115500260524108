<template>
  <div>
    <div v-if="isLoading"><Loader class="mt-20" :content="true" /></div>
    <TableWrapper v-else-if="!isLoading && attendanceRecords.length">
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
          <TH v-permission="campusLevelPermisions">Class</TH>
          <TH v-permission="campusClassLevelPermisions">Section</TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(attendance, index) in attendanceRecords" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Student Name', ['Student Name'])">
            <div @click="redirectToStudentprofile(attendance.user)">
              <SingleUserDisplay :user="attendance.user" label="full_name" :image="true" />
            </div>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Type', ['Type'])">
            <span class="capitalize">
              {{ attendanceType(attendance.checked_type) }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Attendance Time')">
            {{ $filters.getTimeOnly(attendance.checked_at, TIME_CONSTANTS.time12H) }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Inserted At')">
            {{ $filters.getTimeOnly(attendance.inserted_at, TIME_CONSTANTS.DATE_TIME_12) }}
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Class', ['Class'])"
            v-permission="campusLevelPermisions"
          >
            <BadgeDisplay
              :options="attendance.user.users_roles_entities"
              :nested-label="['class', 'title']"
              :is-object-concated="false"
              label="title"
            />
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Section', ['Section'])"
            v-permission="campusClassLevelPermisions"
          >
            <BadgeDisplay
              :options="attendance.user.users_roles_entities"
              :nested-label="['section', 'title']"
              :is-object-concated="false"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>

    <div v-else>
      <slot name="notFound"></slot>
    </div>
  </div>
</template>

<script>
import TIME_CONSTANTS from '@src/constants/date-time-constants'
import { showColumnInDataTable } from '@utils/generalUtil'
import { redirectToStudentMode } from '@src/utils/settings/tenant-user.util.js'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import Loader from '@components/BaseComponent/Loader.vue'
import fileMixin from '@src/mixins/file-mixins'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import { mapState } from 'vuex'
import CAMPUS_ATTENDANCE_DATA from '@/src/router/views/attendance/campus-attendance/campusStudentAttendance.json'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import { SCOPE_LEVELS } from '@src/constants/general-constants'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    SingleUserDisplay,
    Loader,
    BadgeDisplay,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [fileMixin, scrollMixin],
  props: {
    attendanceRecords: {
      type: Array,
      default: () => [],
    },
    currentDate: {
      type: [Date, String],
      default: '',
    },
    filtersData: {
      type: Object,
      default: () => {},
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      TIME_CONSTANTS,
      skipForFilterColumns: ['Student Name', 'Type'],
      tableHeaders: CAMPUS_ATTENDANCE_DATA.tableHeaders,
      campusLevelPermisions: {
        basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL],
      },
      campusClassLevelPermisions: {
        basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.CLASS_LEVEL],
      },
    }
  },
  computed: {
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
    }),
  },
  watch: {
    attendanceRecords: {
      immediate: true,
      handler() {
        this.attendanceRecords.forEach((profile) => {
          profile.image = profile.profile_image
          profile['full_name'] = `${profile.first_name} ${profile.last_name}`
        })
      },
    },
  },
  methods: {
    showColumnInDataTable,
    redirectToStudentprofile(user) {
      if (this.activeRole !== TENANT_ROLES.SECTION_STUDENT) {
        redirectToStudentMode(user, 'student-campus-attendance')
      }
    },
    attendanceType(type) {
      return type === 'checked_in' ? 'attendance in' : 'attendance out'
    },
  },
}
</script>
