<template>
  <TitleWrapper
    :filter-option="true"
    title="CAMP_MARK_ATTEND"
    tooltip-title="CAMP_MARK_ATTEND"
    :display-breadcrumb="true"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    :count="filtersCount"
    @cancel="cancelHanlder"
    @applyFilter="emitFiltersPayload($event, invalid)"
    @clear="clearFilters"
  >
    <template v-slot:filterItems>
      <InputFieldWrapper>
        <UiMultiSelect
          v-if="!isGuardianOrStudent"
          v-model.trim="filtersPayload.user_id"
          title="STUDENTS"
          :options="studentList"
          reduce="id"
          label="full_name"
          class="flex-1 mb-5 sm:mb-0"
          input-color="bg-white"
          :already-selected="selectedStudents"
          :search-through-api="true"
          :filter="FILTERS_LIST.MULTIPLE_USERS"
          @search="getStudents"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model="filtersPayload.checked_type"
          title="TYPE"
          :options="CHECKED_TYPE"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="title"
          reduce="value"
          :filter="FILTERS_LIST.CHECKED_TYPE"
          @emitQuery="updateQuery"
        />
      </InputFieldWrapper>
    </template>
  </TitleWrapper>
</template>

<script>
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { getCountFromObject } from '@utils/generalUtil'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { mapActions, mapState, mapGetters } from 'vuex'
import filterMixins from '@/src/mixins/filter-mixins.js'
import { buildWhereQuery } from '@/src/utils/filters'
import { CHECKED_TYPE } from '@/src/constants/attandance/attandance-constant.js'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiMultiSelect,
    UiSingleSelect,
    TitleWrapper,
    InputFieldWrapper,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      CHECKED_TYPE,
      sectionStudents: [],
      FILTER_KEYS,
      studentList: [],
      $where: {},
      selectedStudents: [],
      FILTERS_LIST,
      filtersPayload: {
        checked_type: '',
        user_id: [],
        displayedColumns: [],
      },
      filtersCount: 0,
    }
  },

  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      currentCampusScope: (state) => state.layout.currentCampusScope.id,
    }),
    ...mapGetters('layout', ['userRole']),
    isGuardianOrStudent() {
      return [TENANT_ROLES.GUARDIAN, TENANT_ROLES.SECTION_STUDENT].includes(this.userRole)
    },
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.getStudents()
        this.clearFilters()
      },
    },

    currentSectionScope: {
      handler() {
        this.getStudents()
        this.clearFilters()
      },
    },
  },
  mounted() {
    this.getStudents()
    this.updateDisplayedColumnList()
  },
  methods: {
    getStudents(query) {
      if (this.isGuardianOrStudent) return
      let alreadySelectedStudents = []
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      this.getStudentsWithFilters(payload).then((res) => {
        this.studentList = res?.records
        this.studentList?.forEach((record) => {
          if (this.filtersPayload && this.filtersPayload.user_id.length) {
            this.filtersPayload.user_id.forEach((id) => {
              if (record.id === id) {
                alreadySelectedStudents.push(record)
              }
            })
          }
          this.selectedStudents = alreadySelectedStudents
        })
      })
    },
    getFiltersCount() {
      this.getStudents()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    ...mapActions('student', ['getStudentsWithFilters']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
